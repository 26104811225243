'use client';

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { TripSummary } from '@jucy-ui/common';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { type StackProps } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { TripInfoLocationDateSummary } from './TripInfoLocationDateSummary';
import { useGetTripEntities } from './hooks';

interface TripInfoSummaryProps extends StackProps {
    trip?: TripSummary;
    amendment?: TripSummary;
    editBtn?: React.ReactNode;
}

export const TripInfoSummary: React.FC<TripInfoSummaryProps> = ({ trip, amendment, direction = 'row', gap, editBtn = 1, sx, ...props }) => {
    const isEdit = Boolean(amendment);
    const { data: tripData } = useGetTripEntities({ trip: trip });
    const { data: amendmentData } = useGetTripEntities({ trip: amendment, enabled: isEdit });
    const hasPickUpData = tripData.pickUpBranch || trip?.pickUpDate;
    const hasDropOffData = tripData.dropOffBranch || trip?.dropOffDate;
    return (
        <Stack direction={direction} sx={mergeSx({ gap }, sx)} {...props}>
            {hasPickUpData ? (
                <TripInfoLocationDateSummary
                    key="pick-up"
                    title="Pick up"
                    editBtn={editBtn}
                    site={tripData.pickUpBranch}
                    date={trip?.pickUpDate}
                    amendedDate={isEdit ? amendment?.pickUpDate : undefined}
                    amendedSite={isEdit ? amendmentData.pickUpBranch : undefined}
                />
            ) : null}
            {hasPickUpData && hasDropOffData ? (
                <Box
                    key="separator"
                    sx={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <ArrowRightIcon />
                </Box>
            ) : null}
            {hasDropOffData ? (
                <TripInfoLocationDateSummary
                    key="drop-off"
                    title="Drop Off"
                    editBtn={editBtn}
                    site={tripData.dropOffBranch}
                    date={trip?.dropOffDate}
                    amendedDate={isEdit ? amendment?.dropOffDate : undefined}
                    amendedSite={isEdit ? amendmentData.dropOffBranch : undefined}
                />
            ) : null}
        </Stack>
    );
};
