'use client';

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { inputBaseClasses } from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import { type TextFieldProps, TextFieldVariants } from '@mui/material/TextField/TextField';
import { PhoneNumberInputContext } from './PhoneInputContext';
import { PhoneNumberInputComponentAdornment } from './PhoneNumberInputComponentAdornment';

export type PhoneNumberInputComponentProps<Variant extends TextFieldVariants = TextFieldVariants> = Omit<TextFieldProps<Variant>, 'onChange' | 'ref'>;

export const PhoneNumberInputComponent = React.forwardRef<HTMLInputElement, PhoneNumberInputComponentProps>((inProps, ref) => {
    const { selectedCountry, setSelectedCountry } = React.useContext(PhoneNumberInputContext);
    const rootRef = React.useRef<HTMLDivElement>(null);

    const { InputProps, sx, ...props } = inProps;
    return (
        <TextField
            {...props}
            sx={mergeSx(
                {
                    [`& .${inputBaseClasses.root}`]: {
                        alignItems: 'stretch',
                    },
                },
                sx
            )}
            ref={rootRef}
            inputRef={ref}
            slotProps={{
                input: {
                    ...InputProps,
                    startAdornment: (
                        <PhoneNumberInputComponentAdornment
                            menuAnchorEl={rootRef}
                            disabled={props.disabled}
                            value={selectedCountry}
                            onChange={(value) => {
                                setSelectedCountry(value);
                            }}
                        />
                    ),
                },
            }}
        />
    );
});
PhoneNumberInputComponent.displayName = 'PhoneNumberInputComponent';
