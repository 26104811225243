import React from 'react';
import { CatalogFloorPlan } from '@jucy/rentals-api-client/rentals-api-v3/models/CatalogFloorPlan';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { JucySlider } from '../../../../../JucySlider';
import { Modal, ModalProps } from '../../../../../Modal';
import { FloorplanImage } from './FloorplanImage';

export interface FloorplansLightBox {
    floorplans: CatalogFloorPlan[];
    onClose: ModalProps['onClose'];
    open: ModalProps['open'];
    title: ModalProps['title'];
    titleIcon: ModalProps['titleIcon'];
    initial?: number;
}

export const FloorplansLightBox: React.FC<FloorplansLightBox> = ({ floorplans, initial, ...props }) => (
    <Modal maxWidth="xl" showClose={true} fullScreen={false} {...props}>
        <DialogContent sx={{ px: 4.5 }}>
            <JucySlider
                showDots={false}
                showNav={true}
                componentProps={{
                    nav: {
                        size: 60,
                    },
                }}
            >
                {floorplans.map((floorplan) => (
                    <Box key={`${floorplan.url}`}>
                        <Typography
                            variant="h3"
                            component="div"
                            sx={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                textAlign: 'center',
                            }}
                        >
                            {floorplan.name}
                        </Typography>
                        <Box
                            sx={{
                                py: 2,
                                px: 4,
                            }}
                        >
                            <FloorplanImage floorplan={floorplan} />
                        </Box>
                    </Box>
                ))}
            </JucySlider>
        </DialogContent>
    </Modal>
);
