'use client';

import React from 'react';
import { DialogProps } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { ModalPaper } from './ModalPaper';
import { DIALOG_WIDTH, DIALOG_WIDTH_WIDER } from './consts';

export interface ModalProps extends DialogProps {
    wider?: boolean;
    title?: string;
    titleIcon?: React.ReactNode;
    showClose?: boolean;
}

export const Modal: React.FC<ModalProps> = ({ title, titleIcon, showClose, classes: dialogClasses, children, onClose, wider, ...props }) => (
    <Dialog
        role="dialog"
        onClose={onClose}
        sx={[
            titleIcon
                ? {
                      '& .MuiDialog-paper': {
                          overflow: 'visible',
                      },
                  }
                : {
                      '& .MuiDialog-paper': {
                          overflow: null,
                      },
                  },
            wider
                ? {
                      '& .MuiDialog-paper': {
                          minWidth: DIALOG_WIDTH_WIDER,
                      },
                  }
                : {
                      '& .MuiDialog-paper': {
                          minWidth: DIALOG_WIDTH,
                      },
                  },
        ]}
        PaperComponent={ModalPaper}
        PaperProps={{ title, icon: titleIcon, showClose, onClose }}
        {...props}
    >
        {children}
    </Dialog>
);
Modal.displayName = 'ModalDialog';
