'use client';

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { StackProps } from '@mui/material';
import Stack from '@mui/material/Stack';

export interface SearchFormDateTimeLocationContainerProps extends Omit<StackProps, 'position'> {
    position?: 'start' | 'end';
}

export const SearchFormDateTimeLocationContainer: React.FC<SearchFormDateTimeLocationContainerProps> = ({
    direction = 'column',
    useFlexGap = true,
    width = '100%',
    children,
    sx,
    position,
    ...props
}) => (
    <Stack {...props} direction={direction} useFlexGap={useFlexGap} sx={mergeSx({ width: width }, sx)}>
        {children}
    </Stack>
);
