'use client';

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { FleetType } from '@jucy-ui/common';
import { FormControlLabelProps } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useRadioGroup } from '@mui/material/RadioGroup';
import { JucyRadioControlLabelButton } from '../../../JucyRadioControlLabelButton';
import { FleetTypeRadioControl } from './FleetTypeRadioControl';

interface FleetTypeRadioControlLabelProps extends Omit<FormControlLabelProps, 'control' | 'value'> {
    fleetType: FleetType;
    error?: boolean;
}

export const FleetTypeRadioControlLabel: React.FC<FleetTypeRadioControlLabelProps> = ({ fleetType, sx, error, ...props }) => {
    const radioGroup = useRadioGroup();
    const checked = radioGroup ? radioGroup.value === fleetType.slug : false;
    return (
        <JucyRadioControlLabelButton ownerState={{ checked: checked, error }} variant="outlined">
            <FormControlLabel
                checked={checked}
                disableTypography={false}
                className="fleet-type-label"
                control={<FleetTypeRadioControl fleetType={fleetType} />}
                value={fleetType.slug}
                sx={mergeSx(
                    {
                        justifyContent: 'right',
                        height: '100%',
                        width: '100%',
                        marginLeft: 0,
                        marginRight: 0,
                    },
                    sx
                )}
                {...props}
                slotProps={{
                    typography: {
                        sx: (t) => ({
                            order: 2,
                            flexShrink: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: t.typography.body1.fontSize,
                            fontWeight: 'inherit',
                        }),
                    },
                }}
            />
        </JucyRadioControlLabelButton>
    );
};
