'use client';

import React, { useRef, useState } from 'react';
import { CountryData, mergeRefs, useGetCountries, useIsAutoFilled, waitForAndClick } from '@jucy-ui/common';
import { TextFieldProps, unstable_useId as useId } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import TextField from '@mui/material/TextField';
import { TextFieldVariants } from '@mui/material/TextField/TextField';
import { CountryListItemButton } from './components/CountryListItemButton';
import { countryAutocompleteFilterOptions, filterSuggestedPhoneCountryOptions } from './lib';

export interface CountryAutocompleteProps<Variant extends TextFieldVariants = TextFieldVariants>
    extends Partial<Omit<AutocompleteProps<CountryData, false, false, false>, 'options' | 'loading'>> {
    label?: React.ReactNode;
    error?: boolean;
    helperText?: React.ReactNode;
    InputProps?: TextFieldProps<Variant>;
    name?: string;
    required?: boolean;
}

export const CountryAutocomplete = React.forwardRef(
    <Variant extends TextFieldVariants = TextFieldVariants>(
        {
            selectOnFocus,
            clearOnBlur,
            handleHomeEndKeys,
            autoHighlight,
            getOptionLabel,
            filterOptions,
            renderOption,
            renderInput,
            includeInputInList,
            error,
            helperText,
            label,
            InputProps,
            onChange,
            ...props
        }: CountryAutocompleteProps<Variant>,
        ref: React.Ref<unknown> | undefined
    ) => {
        const id = useId(props.id) || 'id';
        const { data: countries, isLoading } = useGetCountries();
        const [inputValue, setInputValue] = useState('');
        const inputRef = useRef<HTMLInputElement>();
        const isAutoFilled = useIsAutoFilled(inputRef);
        return (
            <Autocomplete<CountryData, false, false, false>
                {...props}
                ref={ref}
                id={id}
                options={[
                    ...(inputValue ? [] : filterSuggestedPhoneCountryOptions(countries || []).map((c) => ({ key: `suggested-${c.name}`, ...c }))),
                    ...(countries || []),
                ]}
                inputValue={inputValue}
                onInputChange={(event, value, reason) => {
                    props.onInputChange?.(event, value, reason);
                    setInputValue(value);
                    if (value && isAutoFilled) {
                        waitForAndClick(`#${CSS.escape(id)}-option-0`);
                    }
                }}
                loading={isLoading}
                filterOptions={filterOptions || countryAutocompleteFilterOptions}
                getOptionLabel={getOptionLabel || ((option) => (typeof option === 'string' ? option : option.name) || 'unknown')}
                includeInputInList={includeInputInList === undefined ? false : autoHighlight}
                autoHighlight={autoHighlight === undefined ? true : autoHighlight}
                selectOnFocus={selectOnFocus === undefined ? true : selectOnFocus}
                clearOnBlur={clearOnBlur === undefined ? false : clearOnBlur}
                handleHomeEndKeys={handleHomeEndKeys === undefined ? true : handleHomeEndKeys}
                renderOption={
                    renderOption ||
                    ((props, countryData) => (
                        <CountryListItemButton {...props} key={countryData.key || (props as { key: string }).key} countryData={countryData} />
                    ))
                }
                onChange={(event, value, reason, details) => {
                    let countryData = value;
                    if (typeof value === 'string') {
                        countryData = {
                            code: '',
                            phonePrefix: '',
                            name: value,
                        };
                    }
                    onChange?.(event, countryData, reason, details);
                }}
                renderInput={
                    renderInput ||
                    ((params) => (
                        <TextField
                            {...InputProps}
                            {...params}
                            label={label}
                            error={error}
                            helperText={helperText}
                            slotProps={{
                                input: { ...params.InputProps, ...InputProps?.InputProps },
                                htmlInput: { ...params.inputProps, ...InputProps?.inputProps, ref: mergeRefs(inputRef, params.inputProps.ref) },
                                inputLabel: { ...params.InputLabelProps, ...InputProps?.InputLabelProps },
                            }}
                        />
                    ))
                }
            />
        );
    }
);
CountryAutocomplete.displayName = 'CountryAutocomplete';
