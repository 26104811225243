'use client';

import React from 'react';
import { BoxProps, SlotComponentProps, StackProps, styled, svgIconClasses } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useThemeProps } from '@mui/material/styles';
import useSlotProps from '@mui/utils/useSlotProps';

export interface SpecsIconListSlots {
    root?: React.ElementType;
    item?: React.ElementType;
    itemTypography?: React.ElementType;
}

export interface SpecsIconListOwnerState {}

export interface SpecsIconListProps extends StackProps {
    items: { title: string; icon: React.ReactNode; label: React.ReactNode }[];
    className?: string;
    slots?: SpecsIconListSlots;
    slotProps?: {
        root: SlotComponentProps<typeof Stack, object, StackProps>;
        item: SlotComponentProps<typeof Box, object, BoxProps>;
        itemTypography: SlotComponentProps<typeof Typography, object, BoxProps>;
    };
}

const SpecsIconListRoot = styled(Stack, {
    name: 'JucySpecsIconList',
    slot: 'root',
})<{ ownerState?: SpecsIconListOwnerState }>(() => ({
    mx: {
        xs: 'auto',
    },
}));
const SpecsIconListItem = styled(Box, {
    name: 'JucySpecsIconList',
    slot: 'item',
})<{ ownerState: SpecsIconListOwnerState }>(() => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: 'large',
    [`& .${svgIconClasses.root}`]: {
        fontSize: 'x-large',
    },
}));
const SpecsIconListItemTypography = styled(Typography, {
    name: 'JucySpecsIconList',
    slot: 'itemTypography',
})<{ ownerState: SpecsIconListOwnerState }>(({ theme }) => ({
    fontWeight: 700,
    fontSize: 'large',
    marginLeft: theme.spacing(0.5),
}));
export const SpecsIconList = React.forwardRef<HTMLUListElement, SpecsIconListProps>((inProps, ref) => {
    const props = useThemeProps({ props: inProps, name: 'JucySpecsIconList' });
    const { slotProps, className, items, ...other } = props;
    const ownerState = {};
    const RootSlot = props.slots?.root || SpecsIconListRoot;
    const ItemSlot = props.slots?.item || SpecsIconListItem;
    const ItemTypographySlot = props.slots?.itemTypography || SpecsIconListItemTypography;
    const rootProps = useSlotProps({
        elementType: RootSlot,
        externalSlotProps: slotProps?.root,
        externalForwardedProps: other,
        additionalProps: {
            spacing: 2,
            direction: 'row',
            ref,
        },
        ownerState,
        className: className,
    });
    const itemProps = useSlotProps({
        elementType: ItemSlot,
        externalSlotProps: slotProps?.item,
        ownerState,
    });
    const itemTypographyProps = useSlotProps({
        elementType: ItemTypographySlot,
        externalSlotProps: slotProps?.itemTypography,
        ownerState,
    });
    return (
        <RootSlot {...rootProps}>
            {items.map((d) => (
                <ItemSlot key={`details-${d.label}-${d.title}`} {...itemProps}>
                    {d.icon}
                    <ItemTypographySlot {...itemTypographyProps}>{d.label}</ItemTypographySlot>
                </ItemSlot>
            ))}
        </RootSlot>
    );
});
SpecsIconList.displayName = 'SpecsIconList';
