'use client';

import React, { useCallback, useEffect, useState } from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { cn } from '@jucy-ui/common';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { FormControlLabelProps, FormGroupProps, SxProps, TextFieldProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Fade from '@mui/material/Fade';
import FormControl, { formControlClasses } from '@mui/material/FormControl';
import FormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { svgIconClasses } from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import { jucySearchFormClasses } from '../SearchForm/lib/jucySearchFormClasses';

export type SharedFieldProps = TextFieldProps & {
    onVisibilityChanged?: (visible: boolean) => void;
    formGroupOptions?: Partial<FormGroupProps>;
    checkBoxLabelProps?: Partial<FormControlLabelProps>;
    promoCodeInputProps?: Partial<PromoCodeInputProps>;
};
export type PromoCodeFieldProps = Omit<SharedFieldProps, 'onChange'> & {
    sx?: SxProps<Theme>;
    onChange?: (value: string) => void;
};
export type PromoCodeInputProps = SharedFieldProps & {
    isVisible: boolean;
    onVisibilityChanged: (visible: boolean) => void;
};
const PromoCodeInput = React.forwardRef<unknown, PromoCodeInputProps>(
    ({ formGroupOptions, isVisible, onVisibilityChanged, checkBoxLabelProps, ...props }, ref) => (
        <FormGroup
            {...formGroupOptions}
            className={cn(isVisible ? 'checked' : 'unchecked', formGroupOptions?.className, jucySearchFormClasses.promoCode)}
            sx={mergeSx({ justifyContent: 'center' }, formGroupOptions?.sx)}
        >
            <Fade
                appear={false}
                in={isVisible}
                onEnter={(container) => {
                    const input = container.querySelector('input');
                    if (input) {
                        input.focus();
                    }
                }}
            >
                <div>
                    <TextField
                        autoFocus={true}
                        fullWidth={true}
                        {...props}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                onVisibilityChanged(false);
                            }
                        }}
                        slotProps={{
                            htmlInput: {
                                ref: ref,
                            },
                        }}
                    />
                </div>
            </Fade>
            <Fade in={!isVisible} appear={false}>
                <Box sx={{ position: 'absolute' }}>
                    <FormControlLabel
                        {...checkBoxLabelProps}
                        className={cn('JucyPromoToggle', checkBoxLabelProps?.className)}
                        checked={false}
                        disabled={props.disabled}
                        sx={mergeSx((theme) => {
                            const borderColor = theme.settings.general.outlinedInputBorderColor;
                            const borderHoverColor = theme.settings.general.outlinedInputBorderColorHover;
                            return {
                                [`& .${formControlLabelClasses.label}`]: { fontSize: 'smaller', lineHeight: 0.9 },
                                [`& .${svgIconClasses.root}`]: {
                                    transition: theme.transitions.create(['stroke', 'fill'], {
                                        duration: theme.transitions.duration.short,
                                    }),
                                    fill: theme.palette.common.white,
                                    stroke: borderColor,
                                },
                                '&:hover': {
                                    [`& .${svgIconClasses.root}`]: {
                                        stroke: borderHoverColor,
                                    },
                                },
                            };
                        }, checkBoxLabelProps?.sx)}
                        control={
                            <Checkbox
                                name="toggle-promo-code"
                                disableRipple={true}
                                icon={<SquareRoundedIcon />}
                                onChange={(e) => onVisibilityChanged(Boolean(e.target.value))}
                                size="small"
                            />
                        }
                        label={props.label as string}
                    />
                </Box>
            </Fade>
        </FormGroup>
    )
);
PromoCodeInput.displayName = 'PromoCodeField';
export const PromoCodeField = React.forwardRef<unknown, PromoCodeFieldProps>(
    ({ value: passedValue, onChange, onVisibilityChanged, sx, className, ...props }, ref) => {
        const [value, setValue] = useState<unknown | undefined>(() => passedValue || '');
        const [isVisible, setIsVisible] = useState(() => Boolean(value));
        const handleChange: TextFieldProps['onChange'] = useCallback(
            (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const value = (event.target as HTMLInputElement).value;
                setValue(value);
                onChange?.(value);
            },
            [onChange]
        );
        const handleVisibilityChanged = useCallback(
            (isTicked: boolean) => {
                setIsVisible((oldIsShown) => {
                    if (oldIsShown !== isTicked) {
                        onVisibilityChanged?.(isTicked);
                    }
                    return isTicked;
                });
            },
            [onVisibilityChanged]
        );
        useEffect(() => {
            setValue(passedValue || '');
        }, [passedValue]);
        useEffect(() => {
            handleVisibilityChanged(Boolean(value || isVisible));
        }, [value, handleVisibilityChanged, isVisible]);
        return (
            <FormControl
                className={className}
                sx={mergeSx(
                    {
                        [`& .${formControlClasses.root}`]: {
                            marginBottom: 0,
                        },
                    },
                    sx
                )}
            >
                <PromoCodeInput
                    ref={ref}
                    value={value}
                    onChange={handleChange}
                    isVisible={isVisible}
                    onVisibilityChanged={handleVisibilityChanged}
                    {...props}
                />
            </FormControl>
        );
    }
);
PromoCodeField.displayName = 'PromoCodeInput';
