'use client';

import React, { useCallback } from 'react';
import type { CatalogItem, FleetCategory } from '@jucy-ui/common';
import { SlotComponentProps } from '@mui/material';
import { useThemeProps } from '@mui/material/styles';
import useSlotProps from '@mui/utils/useSlotProps';
import { FleetCategoryTeaserCard } from '../TeaserCard/FleetCategoryTeaserCard';
import { AvailabilityGridOwnerState } from './AvailabilityGrid';

export interface AvailabilityGridRowSlots {
    root?: typeof FleetCategoryTeaserCard;
}
export interface AvailabilityGridRowProps {
    fleetCategory: FleetCategory;
    catalogItem?: CatalogItem;
    onSelect?: (fleetCategory: FleetCategory) => void;
    isActive?: boolean;
    isLoading?: boolean;
    isHighlighted?: boolean;
    className?: string;
    action?: 'create' | 'edit';
    ownerState: AvailabilityGridOwnerState;
    slots?: AvailabilityGridRowSlots;
    slotProps?: {
        root: SlotComponentProps<typeof FleetCategoryTeaserCard, object, AvailabilityGridOwnerState>;
    };
}

export const AvailabilityGridRow = React.forwardRef<HTMLDivElement, AvailabilityGridRowProps>((inProps, ref) => {
    const props = useThemeProps({ props: inProps, name: 'AvailabilityGridRow' });
    const { isLoading, catalogItem, isActive, fleetCategory, onSelect, isHighlighted, className, ownerState, slotProps, action, ...other } = inProps;
    const onFleetCategorySelected = useCallback(() => {
        onSelect?.(fleetCategory);
    }, [fleetCategory, onSelect]);

    const RootSlot = props.slots?.root || FleetCategoryTeaserCard;
    const rootProps = useSlotProps({
        elementType: RootSlot,
        externalSlotProps: slotProps?.root,
        externalForwardedProps: other,
        additionalProps: {
            ref,
            className: className,
            isActive: isActive,
            isHighlighted: isHighlighted,
            fleetCategory: fleetCategory,
            catalogItem: catalogItem as CatalogItem,
            isLoading: isLoading,
            currencyCode: fleetCategory.total.currencyCode,
            onSelect: onFleetCategorySelected,
            action: action,
            sx: { mb: 2 },
        },
        ownerState,
        className: className,
    });

    return <FleetCategoryTeaserCard {...rootProps} />;
});
AvailabilityGridRow.displayName = 'AvailabilityGridRow';
