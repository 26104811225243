'use client';

import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';
import Skeleton from '@mui/material/Skeleton';
import { useFleetTypeRadioGroupContext } from '../lib';

const avatarSizeRow = {
    height: 40,
    width: 40,
};
const avatarSizeCol = {
    height: 30,
    width: 30,
};
const radioSize = {
    height: 24,
    width: 24,
};
export const FleetTypeRadioSkeleton: React.FC<{ width?: string | number }> = ({ width }) => {
    const { direction } = useFleetTypeRadioGroupContext();
    const avatarSize = direction === 'row' ? avatarSizeRow : avatarSizeCol;
    return (
        <Card variant="outlined" sx={{ px: 0.5, width: direction === 'column' ? '100%' : width || '204px', display: 'flex', alignItems: 'center' }}>
            <Grid spacing={0.5} container sx={{ height: '100%', flexWrap: 'nowrap', flex: '1 1' }}>
                <Grid
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flex: `0 0 ${avatarSize.width + 4}px`,
                    }}
                >
                    <Skeleton animation="wave" variant="circular" sx={avatarSize} />
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: 'center', flex: '1 1' }}>
                    <Skeleton animation="wave" variant="rounded" sx={{ width: '100%' }}></Skeleton>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: 'center', flex: `0 0 ${radioSize.width + 4}px` }}>
                    <Skeleton animation="wave" variant="circular" height={radioSize.height} width={radioSize.width} sx={{ ml: 'auto', mr: '1px' }} />
                </Grid>
            </Grid>
        </Card>
    );
};
