'use client';

import React from 'react';
import { CountryData, useGetCountries } from '@jucy-ui/common';
import ClearIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { type UseAutocompleteProps, useAutocomplete } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import { JucyAlert } from '../../JucyAlert';
import { LoadingContainer } from '../../LoadingContainer';
import { Scrollbar } from '../../Scrollbar';
import { CountryListItemButton } from './components/CountryListItemButton';
import { filterSuggestedPhoneCountryOptions } from './lib';

export const CountrySelectList = <
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
>(
    props: Omit<UseAutocompleteProps<CountryData, Multiple, DisableClearable, FreeSolo>, 'options'>
) => {
    const { data: countries, isLoading, error } = useGetCountries();
    const { inputValue, getRootProps, getInputLabelProps, getInputProps, getListboxProps, getOptionProps, groupedOptions, getClearProps } =
        useAutocomplete({
            ...props,
            options: countries || [],
            open: true,
            disabled: Boolean(isLoading || error),
            getOptionKey: (option) => {
                if (typeof option === 'string') {
                    return option;
                }
                return option.key || option.code;
            },
            getOptionLabel: (option) => (typeof option === 'string' ? option : option.name),
        });
    if (error) {
        return (
            <JucyAlert color="primary" variant="outlined" severity="warning">
                Whoops! Unable to load countries {error.message}
            </JucyAlert>
        );
    }
    const filteredOptions = [
        ...(inputValue ? [] : filterSuggestedPhoneCountryOptions(countries || []).map((c) => ({ key: `suggested-${c.name}`, ...c }))),
        ...(((inputValue ? groupedOptions : countries) || []) as CountryData[]),
    ];
    return (
        <LoadingContainer isLoading={isLoading}>
            <div {...getRootProps()}>
                <TextField
                    autoFocus={true}
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton size="small" {...(getClearProps() as IconButtonProps)}>
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        },

                        htmlInput: getInputProps(),
                        inputLabel: getInputLabelProps(),
                    }}
                />
                {filteredOptions.length > 0 ? (
                    <Scrollbar
                        sx={{
                            height: 300,
                            width: '100%',
                            maxWidth: 370,
                        }}
                    >
                        <List {...getListboxProps()} sx={{ p: 0 }}>
                            {(filteredOptions as CountryData[]).map((option) => {
                                const index = groupedOptions.findIndex((o) => (o as CountryData).code === option.code);
                                return <CountryListItemButton {...getOptionProps({ option, index })} key={option.code} countryData={option} />;
                            })}
                        </List>
                    </Scrollbar>
                ) : null}
            </div>
        </LoadingContainer>
    );
};
