'use client';

import React from 'react';
import { cn } from '@jucy-ui/common';
import { RadioGroupProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid2';
import RadioGroup from '@mui/material/RadioGroup';
import { PaymentTypeRadioControlLabel } from './components';
import { type PaymentType } from './lib';

export interface PaymentOptionRadioGroupProps extends RadioGroupProps {
    labels?: Record<string, string>;
    helperText?: React.ReactNode;
    error?: boolean;
    paymentTypes: PaymentType[];
    disabled?: boolean;
}

export const PaymentOptionRadioGroup = React.forwardRef<HTMLInputElement, PaymentOptionRadioGroupProps>(
    ({ labels, paymentTypes, error, helperText, sx, disabled, ...props }, ref) => (
        <FormControl error={error} variant="standard" sx={sx} fullWidth={true} disabled={disabled} className={cn(error ? 'error' : undefined)}>
            <RadioGroup row ref={ref} {...props}>
                <Grid container spacing={3}>
                    {paymentTypes
                        .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
                        .map((paymentType) => (
                            <Grid
                                key={paymentType.id}
                                size={{
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                    lg: 6,
                                }}
                            >
                                <PaymentTypeRadioControlLabel
                                    disabled={disabled}
                                    error={error}
                                    paymentType={paymentType}
                                    label={labels?.[paymentType.id] || paymentType.label}
                                />
                            </Grid>
                        ))}
                </Grid>
                {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            </RadioGroup>
        </FormControl>
    )
);
PaymentOptionRadioGroup.displayName = 'PaymentOptionRadioGroup';
