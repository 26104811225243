'use client';

import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import React from 'react';
import { CatalogItem, useResizeObserver } from '@jucy-ui/common';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import GroupsIcon from '@mui/icons-material/Groups';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WorkIcon from '@mui/icons-material/Work';
import { SlotComponentProps, styled } from '@mui/material';
import Button from '@mui/material/Button';
import { useThemeProps } from '@mui/material/styles';
import useSlotProps from '@mui/utils/useSlotProps';
import { AspectRatio } from '../../../../AspectRatio';
import { FeatureList } from '../../../../FeatureList';
import { SpecsIconList, SpecsIconListProps } from '../../../../SpecsIconList';
import { FleetCategoryTeaserCardOwnerState } from '../types';
import { FleetCategoryDetailsDialog } from './FleetCategoryDetailsDialog';

export interface FleetCategoryTeaserCardDetailsSlots {
    root?: React.ElementType;
    featureList?: typeof FeatureList;
    moreDetailsButton?: React.ElementType;
    iconList?: React.ElementType;
}

interface FleetCategoryTeaserCardDetailsProps {
    ownerState: FleetCategoryTeaserCardOwnerState;
    slots?: FleetCategoryTeaserCardDetailsSlots;
    className?: string;
    slotProps?: {
        root: SlotComponentProps<'div', object, FleetCategoryTeaserCardOwnerState>;
        featureList: SlotComponentProps<typeof FeatureList, object, FleetCategoryTeaserCardOwnerState>;
        moreDetailsButton: SlotComponentProps<typeof Button, object, FleetCategoryTeaserCardOwnerState>;
        iconList: SlotComponentProps<typeof SpecsIconList, object, FleetCategoryTeaserCardOwnerState>;
    };
}

const FleetCategoryTeaserCardDetailsRoot = styled('div', {
    name: 'JucyFleetCategoryTeaserCard',
    slot: 'details',
})<{ ownerState?: FleetCategoryTeaserCardOwnerState }>(() => ({
    maxWidth: '100%',
    display: 'flex',
    flex: '1 1',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
}));
const MoreDetailsButton = styled(Button, {
    name: 'JucyFleetCategoryTeaserCard',
    slot: 'moreDetailsButton',
})<{ ownerState?: FleetCategoryTeaserCardOwnerState }>(({ theme }) => ({
    textTransform: 'unset',
    fontSize: 'smaller',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
        alignSelf: 'flex-start',
    },
}));
const getDetailIcons = (catalogItem: CatalogItem) => {
    const icons: SpecsIconListProps['items'] = [];
    if (catalogItem.seatCount) {
        icons.push({ title: 'Seats', icon: <GroupsIcon />, label: catalogItem.seatCount });
    }
    if (catalogItem.sleepCount) {
        icons.push({ title: 'Sleeps', icon: <AirlineSeatIndividualSuiteIcon />, label: catalogItem.sleepCount });
    }
    if (catalogItem.luggageLarge) {
        icons.push({ title: 'Luggage large', icon: <BusinessCenterIcon />, label: catalogItem.luggageLarge });
    }
    if (catalogItem.luggageSmall) {
        icons.push({ title: 'Luggage small', icon: <WorkIcon />, label: catalogItem.luggageSmall });
    }
    return icons;
};
export const FleetCategoryTeaserCardDetails = React.forwardRef<HTMLDivElement, FleetCategoryTeaserCardDetailsProps>((inProps, ref) => {
    useSignals();
    const props = useThemeProps({ props: inProps, name: 'FleetCategoryTeaserCardDetails' });
    const { slotProps, className, ownerState, ...other } = props;
    const { catalogItem } = ownerState;
    const modelOpen = useSignal(false);
    const RootSlot = props.slots?.root || FleetCategoryTeaserCardDetailsRoot;
    const FeatureListSlot = props.slots?.featureList || FeatureList;
    const MoreDetailsButtonSlot = props.slots?.moreDetailsButton || MoreDetailsButton;
    const IconListSlot = props.slots?.iconList || SpecsIconList;
    const rootProps = useSlotProps({
        elementType: RootSlot,
        externalSlotProps: slotProps?.root,
        externalForwardedProps: other,
        additionalProps: {
            ref,
        },
        ownerState,
        className: className,
    });
    const { maxItems, resizeObserverRef } = useFeatureListResizeObserver();
    const featureListProps = useSlotProps({
        elementType: FeatureListSlot,
        externalSlotProps: slotProps?.featureList,
        additionalProps: {
            items: catalogItem.highlightedFeatures || [],
            max: maxItems.value,
            disablePadding: true,
            dense: true,
            sx: { mt: '-5px' },
        },
        ownerState,
        className: className,
    });
    const moreDetailsButtonProps = useSlotProps({
        elementType: MoreDetailsButtonSlot,
        externalSlotProps: slotProps?.moreDetailsButton,
        additionalProps: {
            variant: 'text',
            'data-cy': 'more-details-link',
            href: catalogItem.url,
            title: `More details: ${catalogItem.name}`,
            onClick: () => (modelOpen.value = true),
            rel: 'noopener noreferrer',
        },
        ownerState,
        className: className,
    });
    const iconListProps = useSlotProps({
        elementType: IconListSlot,
        externalSlotProps: slotProps?.iconList,
        additionalProps: {
            items: getDetailIcons(catalogItem),
            sx: { mb: '-5px', mx: { xs: 'auto', sm: 0 } },
        },
        ownerState,
        className: className,
    });
    return (
        <RootSlot {...rootProps}>
            <TeaserCardDetailsResizeObserver ref={resizeObserverRef} />
            <FeatureListSlot {...featureListProps} />
            <MoreDetailsButtonSlot {...moreDetailsButtonProps}>
                Get more details
                <OpenInNewIcon sx={{ fontSize: '15px', ml: 1 }} />
            </MoreDetailsButtonSlot>
            <IconListSlot {...iconListProps} />
            <FleetCategoryDetailsDialog catalogItem={catalogItem} open={modelOpen.value} onClose={() => (modelOpen.value = false)} />
        </RootSlot>
    );
});
FleetCategoryTeaserCardDetails.displayName = 'FleetCategoryTeaserCardDetails';

const TeaserCardDetailsResizeObserver = React.forwardRef<HTMLDivElement>((props, ref) => (
    <AspectRatio ref={ref} ratio={274 / 144} sx={{ position: 'absolute', width: '100%' }} />
));
TeaserCardDetailsResizeObserver.displayName = 'TeaserCardDetailsResizeObserver';

const useFeatureListResizeObserver = () => {
    const maxItems = useSignal(6);
    const resizeObserverRef = useResizeObserver((entries) => {
        const entry = entries[0];
        if (entry.contentRect.height < 106) {
            maxItems.value = 4;
        } else if (entry.contentRect.height < 130) {
            maxItems.value = 5;
        } else {
            maxItems.value = 6;
        }
    });
    return { maxItems, resizeObserverRef };
};
