'use client';

import React, { useState } from 'react';
import type { TripDetails, TripSummary } from '@jucy-ui/common';
import { CatalogItem, FleetType, Site, mapBookingItems } from '@jucy-ui/common';
import { ButtonProps, type CardProps, SlotComponentProps, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid2';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useThemeProps } from '@mui/material/styles';
import useSlotProps from '@mui/utils/useSlotProps';
import startCase from 'lodash/startCase';
import numWords from 'num-words';
import { AspectRatio } from './AspectRatio';
import { FLEET_CAT_IMG_RATIO } from './FleetCategory/components/TeaserCard/lib';
import { LoadingContainer } from './LoadingContainer';
import { TripInfoSummary } from './TripInfoSummary';
import { useGetTripEntities } from './hooks';
import { ExpandMoreIcon } from './svg-icons';

export interface ReservationSummaryCardSlots {
    root?: React.ElementType;
    editLocationDatesBtn?: React.ElementType;
    editExcessReductionBtn?: React.ElementType;
    editSecondaryProductsBtn?: React.ElementType;
}

export interface ReservationSummaryCardProps extends Omit<CardProps, 'title' | 'onSelect' | 'ref'> {
    trip: Partial<TripDetails>;
    className?: string;
    slots?: ReservationSummaryCardSlots;
    slotProps?: {
        root?: SlotComponentProps<typeof Card, object, ReservationSummaryCardOwnerState>;
        editLocationDatesBtn?: SlotComponentProps<'div', object, ReservationSummaryCardOwnerState>;
        editExcessReductionBtn?: SlotComponentProps<'div', object, ReservationSummaryCardOwnerState>;
        editSecondaryProductsBtn?: SlotComponentProps<'div', object, ReservationSummaryCardOwnerState>;
    };
}
export interface ReservationSummaryCardOwnerState {
    trip: Partial<TripDetails>;
    tripData: { pickUpBranch?: Site; dropOffBranch?: Site; catalogItem?: CatalogItem; fleetType?: FleetType };
}

export const ReservationSummaryCard = React.forwardRef<HTMLDivElement, ReservationSummaryCardProps>(({ variant = 'outlined', ...props }, ref) => {
    const { data, isLoading: isBookingEntitiesLoading } = useGetTripEntities({
        trip: {
            fleetType: props.trip.fleetCategory?.fleetTypeCode,
            fleetCategory: props.trip.fleetCategory?.code,
            pickUpLocation: props.trip.pickUpLocation,
            dropOffLocation: props.trip.dropOffLocation,
        },
    });
    const { catalogItem, pickUpBranch, dropOffBranch, fleetType } = data;
    return (
        <LoadingContainer isLoading={isBookingEntitiesLoading}>
            <ReservationSummaryCardComponent
                ref={ref}
                variant={variant}
                {...props}
                catalogItem={catalogItem}
                pickUpBranch={pickUpBranch}
                dropOffBranch={dropOffBranch}
                fleetType={fleetType}
            />
        </LoadingContainer>
    );
});
ReservationSummaryCard.displayName = 'ReservationSummaryCard';

interface ReservationSummaryCardComponentProps extends ReservationSummaryCardProps {
    catalogItem?: CatalogItem;
    pickUpBranch?: Site;
    dropOffBranch?: Site;
    fleetType?: FleetType;
}

export const ReservationSummaryCardComponent = React.forwardRef<HTMLDivElement, ReservationSummaryCardComponentProps>((inProps, ref) => {
    const props = useThemeProps({ props: inProps, name: 'JucyReservationSummaryCard' });
    const { trip, className, catalogItem, pickUpBranch, dropOffBranch, fleetType, slotProps, ...other } = props;
    const [showExtras, setShowExtras] = useState(false);
    const toggleShowExtras: ButtonProps['onClick'] = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setShowExtras(!showExtras);
    };
    const ownerState = {
        trip,
        tripData: { pickUpBranch, dropOffBranch, catalogItem, fleetType },
    };
    const RootSlot = props.slots?.root || ReservationSummaryCardRoot;
    const EditLocationDatesBtnSlot = props.slots?.editLocationDatesBtn;
    const EditExcessReductionBtnSlot = props.slots?.editExcessReductionBtn;
    const EditSecondaryProductsBtnSlot = props.slots?.editSecondaryProductsBtn;

    const rootProps = useSlotProps({
        elementType: RootSlot,
        externalSlotProps: slotProps?.root,
        externalForwardedProps: other,
        additionalProps: {
            ref,
        },
        ownerState,
        className: className,
    });

    const editLocationDatesBtnProps = useSlotProps({
        elementType: EditLocationDatesBtnSlot,
        externalSlotProps: slotProps?.editLocationDatesBtn,
        ownerState,
    });
    const editExcessReductionBtnProps = useSlotProps({
        elementType: EditExcessReductionBtnSlot,
        externalSlotProps: slotProps?.editExcessReductionBtn,
        ownerState,
    });

    const editSecondaryProductsBtnProps = useSlotProps({
        elementType: EditSecondaryProductsBtnSlot,
        externalSlotProps: slotProps?.editSecondaryProductsBtn,
        ownerState,
    });

    if (!catalogItem || !pickUpBranch || !dropOffBranch || !fleetType) {
        return <LinearProgress />;
    }
    const mappedTrip = mapBookingItems(trip);
    const fleetCategoryImageUrl = catalogItem.scenicImage || catalogItem.gallery[0]?.original;
    const extras = mappedTrip.selectedExtras;
    const numberOfExtras = startCase(numWords(extras.length));
    return (
        <RootSlot {...rootProps}>
            <CardContent sx={{ maxWidth: '100%', '&:last-child': { pb: 2 } }}>
                <Grid container spacing={3}>
                    <Grid size={{ sm: 12, md: 4 }} sx={{ textAlign: 'center' }}>
                        {fleetCategoryImageUrl ? (
                            <AspectRatio ratio={FLEET_CAT_IMG_RATIO} sx={{ mb: 1 }}>
                                <CardMedia component="img" sx={{ borderRadius: 1 }} image={catalogItem.scenicImage} alt={catalogItem.name} />
                            </AspectRatio>
                        ) : null}
                        <Typography variant="h4">{catalogItem.name}</Typography>
                        <Typography variant="subtitle2">{catalogItem.specifications.Vehicle}</Typography>
                    </Grid>
                    <Grid size={{ sm: 12, md: 8 }}>
                        <Stack spacing={2} sx={{ maxWidth: 360 }}>
                            <TripInfoSummary
                                editBtn={EditLocationDatesBtnSlot ? <EditLocationDatesBtnSlot {...editLocationDatesBtnProps} /> : undefined}
                                trip={trip as TripSummary}
                            />
                            {mappedTrip.selectedExcess ? (
                                <List
                                    sx={{ p: 0 }}
                                    subheader={
                                        <ListItem disablePadding>
                                            <Typography variant="button" component="h3" sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                Excess reduction
                                                {EditExcessReductionBtnSlot ? (
                                                    <EditExcessReductionBtnSlot {...editExcessReductionBtnProps} />
                                                ) : undefined}
                                            </Typography>
                                        </ListItem>
                                    }
                                >
                                    <Typography variant="caption" disablePadding component={ListItem}>
                                        {mappedTrip.selectedExcess.name}
                                    </Typography>
                                </List>
                            ) : null}
                            {extras.length ? (
                                <List
                                    sx={{ p: 0 }}
                                    component="div"
                                    subheader={
                                        <ListItem component="div" sx={{ display: 'flex', alignItems: 'center' }} disablePadding>
                                            <Button
                                                color="charcoal"
                                                size="small"
                                                sx={{ px: 2, ml: -2 }}
                                                variant="text"
                                                endIcon={<ExpandMoreIcon expand={showExtras} />}
                                                onClick={toggleShowExtras}
                                            >
                                                <Typography variant="button" component="h3">
                                                    {numberOfExtras} extra{extras.length !== 1 && 's'}
                                                </Typography>
                                            </Button>

                                            {EditSecondaryProductsBtnSlot ? (
                                                <Typography variant="button" component="h3">
                                                    <EditSecondaryProductsBtnSlot {...editSecondaryProductsBtnProps} />
                                                </Typography>
                                            ) : null}
                                        </ListItem>
                                    }
                                >
                                    <Collapse in={showExtras} timeout="auto" unmountOnExit>
                                        {extras.map((l) => (
                                            <Typography variant="caption" disablePadding component={ListItem} key={l.productCode || l.productId}>
                                                {l.allowMultiple ? `${l.qty} x` : ''} {l.name}
                                            </Typography>
                                        ))}
                                    </Collapse>
                                </List>
                            ) : null}
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </RootSlot>
    );
});
ReservationSummaryCardComponent.displayName = 'ReservationSummaryCardComponent';

const ReservationSummaryCardRoot = styled(Card, {
    name: 'ReservationSummaryCard',
    slot: 'root',
})(() => undefined);
