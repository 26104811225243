'use client';

import React, { useState } from 'react';
import { CatalogItem } from '@jucy-ui/common';
import { FleetTypeCode } from '@jucy/rentals-api-client/rentals-api-v3';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { JucyMuiTabPanel } from '../../../../../Tabs';
import { FleetTypeIcon } from '../../../../../svg-icons';
import { FloorplansCarousel } from '../FloorplansCarousel/FloorplansCarousel';
import { FloorplansLightBox } from '../FloorplansCarousel/FloorplansLightBox';
import { getFleetCategoryDetailsDialogTabPanelProps } from './fleetCategoryDetailsDialogTabConsts';

export const SpecsTab: React.FC<{ activeTab: string; catalogItem: CatalogItem; sx?: SxProps<Theme> }> = ({ activeTab, catalogItem, sx }) => {
    const [lightBoxOpen, setLightBoxOpen] = useState(false);
    const [initial, setInitial] = useState(0);
    return (
        <JucyMuiTabPanel activeValue={activeTab} {...getFleetCategoryDetailsDialogTabPanelProps('specs')} sx={sx}>
            <Box sx={{ pt: 2, '& :first-child': { mt: 0 } }} dangerouslySetInnerHTML={{ __html: catalogItem.specificationsDescription || '' }} />
            <Table sx={{ mt: 2, mb: 2 }} size="small">
                <TableBody>
                    {Object.entries(catalogItem.specifications).map(([key, value]) => (
                        <TableRow hover={true} key={key}>
                            <TableCell sx={{ fontWeight: 'bold' }}>{key}</TableCell>
                            <TableCell className="border-bottom">{value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {catalogItem.floorPlans?.length ? (
                <>
                    <Typography
                        sx={{
                            fontWeight: '700',
                            fontSize: 'Typography',
                            mt: 1,
                        }}
                    >
                        Floor plans
                    </Typography>
                    <FloorplansLightBox
                        title={`${catalogItem.name} floor plans`}
                        titleIcon={<FleetTypeIcon fleetType={catalogItem.fleetTypeSlug as FleetTypeCode} />}
                        open={lightBoxOpen}
                        onClose={() => setLightBoxOpen(false)}
                        floorplans={catalogItem.floorPlans || []}
                        initial={initial}
                    />
                    <FloorplansCarousel
                        floorplans={catalogItem.floorPlans || []}
                        onFloorplanClicked={(floorplan, index) => {
                            setLightBoxOpen(true);
                            setInitial(index);
                        }}
                    />
                </>
            ) : null}
        </JucyMuiTabPanel>
    );
};
