'use client';

import React from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { SvgIconProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ExpandMoreProps extends SvgIconProps {
    expand: boolean;
}

export const ExpandMoreIcon = styled(({ expand, ...props }: ExpandMoreProps) => <ExpandMore {...props} />)(({ theme }) => ({
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
    ],
}));
